@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap');

$primary_color:#060708;
$secondary-color:#706f6d;
$white_color:#fff;
$black_color:#060708;
$dark-color:#706f6d;
$light-color:#e8e8e8;
$font-family-base: 'Montserrat', sans-serif;

@media (min-width: 768px) {
.w-md-30{ width:30%;}
.w-md-40{ width:40%;}
}
.w-80{width:80%;}
.text-primary{
    color:$primary-color !important;
    font-weight:bold;
}
.btn-primary{
    background:$primary-color !important;
    border-color:$primary-color !important;
    &:hover{
        background:$secondary-color !important;
        border-color:$secondary-color !important;
    }
}
