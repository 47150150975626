@import 'variables';

html {
    font-size: 62.5%;
}

body {
    background: $white-color;
    font-size: 1.6rem;
    font-family: $font-family-base
}

.btn {
    font-size: 1.2rem;
    font-family: $font-family-base
}

.header {
    width: 100%;

    &_social{
        > * {
            display: block;
            color: $black-color;
            font-size: 1.4rem;
            line-height: 1;
            width: 2.5rem;
            height: 4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .5s;
            text-decoration: none;
            padding:0 2rem;
            &:hover {
                color: $light-color;
                background: $secondary-color;
            }
        }
        &--icon{
            width:3rem;
            svg{
                width:2.6rem;
                height: 2.6rem;
            }
        }
    }

    &_navbar>button, &_navbar>a {
        display: block;
        color: $black-color;
        font-size: 1.6rem;
        line-height: 1;
        height: 4rem;
        padding: 0 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .5s;
        text-decoration: none;
        font-weight: bold;

        &:hover,
        &.current {
            text-decoration: underline;
        }
    }
}
.popover-container{
    a {
        text-decoration:none;
        color:$dark-color;
    }
    &:hover{
        background:$secondary-color !important;
        a {
            color:$white-color;
        }
    }
}

.footer {
    width: 100%;
    border-top: 1px solid rgba($black-color, .7);
    margin-top: 5rem;

    &_social>a {
        display: block;
        color: $black-color;
        font-size: 1.4rem;
        line-height: 1;
        border-right: 1px solid $black-color;
        width: 2.5rem;
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .5s;
        text-decoration: none;

        &:first-child {
            border-left: 1px solid $black-color;
        }

        &:hover {
            color: $light-color;
            background: $black-color;
        }
    }

    &_navbar {
        display: block;
        color: $black-color;
        font-size: 1.6rem;
        line-height: 4rem;
        height: 4rem;
        padding: 0 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .5s;
        font-weight: bold;
    }
}

.homepage-slider {
    overflow: hidden;
    position: relative;
    background: $light-color;
    height:50vw;
    background:url("./images/K3cover.jpg") no-repeat top center;
    background-size:cover;
    &_logo{
        background-color:rgba($white-color,0.6);
        width:30rem;
        >img{
            width:100%;
        }
    }
    @media (max-width: 768px) {
        background-size:contain;
        height:60vw;
        background-position:top center;
        &_logo{
            width:30vw;
            margin-top:5vw
        }
    }
}

.new-construction {
    &_box {
        padding: 0;
        height: 50%;
        overflow: hidden;

        img {
            width: 100%;
            transition: all .5s;
        }

        &:hover {
            img {
                filter: blur(.1rem);
                -webkit-filter: blur(.1rem);
            }
        }
    }
    &_absolute{
        @media (min-width: 768px) {
            position:absolute;
            top:0;
            bottom:0;
            left:0;
            right:0;
            z-index:0;
        }
        .container{
            height:100%;
        }
        &--info {
            @media (min-width: 768px) {
                position:absolute;
                top:0;
                bottom:0;
                left:0;
                width: 50%;
            }
            background: rgba($white-color, .8);
            font-size: 1.6rem;
            overflow:auto;
            z-index:10000
        }
    }
}

.btns-container {
    @media (min-width: 768px) {
    position: absolute;
    bottom:0;
    left:1.5rem;
    }
    button{
        text-transform: uppercase;
        font-size: 1.6rem;
    }
}
.renovated-apt {
    &_img {
        border-radius: .5rem;
        display: block;
        overflow: hidden;
        height:100%;
        img {
            width: 100%;
            height:100%;
        }

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: #0008;
            transition: all .5s;
        }

        &:hover {
            &:before {
                background: #0004
            }
            .renovated-apt_img--title {
                button{
                    display:block;
                }
            }
            .renovated-apt_availability {
                    display:block;
            }
        }

        &--title {
            color: #fff;
            position: absolute;
            bottom: 5%;
            left: 0;
            width: 100%;
            z-index: 111;
            text-align: center;
            cursor:pointer;
            button{
                display:none;
            }
        }
    }


    &_availability {
        background: $primary_color;
        color: $white-color;
        display: block;
        position: absolute;
        top: 10px;
        right: -20px;
        font-size: 1.2rem;
        width:10rem;
        text-align:center;
        transform: rotate(30deg);
        display:none;
    }
}
.MuiBox-root {
    outline:0
}
.contact-form{
    h1{
        font-size:4rem;
    }
    h3{
        font-size:3rem;
    }
    a{
        text-decoration:none;
        color:$primary_color;
        &:hover{
            color:$secondary_color;
        }
    }
}